.navbar-brand-mb {
  vertical-align: top;
  padding-top: 5px;
  padding-left: 10px;
  display: inline-block;
}

.forgotpassword-mt {
  margin-top: 200px;
}

.text-xsm {
  font-size: 14px;
}

.vcenter {
  margin-left: 45px;
  margin-top: -35px;
}

.white {
  color: white;
}

.navLogin {
  margin-top: 20px;
  text-decoration: none;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  color: blue;
}
